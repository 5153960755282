// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-ja-info-index-js": () => import("./../../../src/pages/ja/info/index.js" /* webpackChunkName: "component---src-pages-ja-info-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-tutorial-js": () => import("./../../../src/pages/tutorial.js" /* webpackChunkName: "component---src-pages-tutorial-js" */),
  "component---src-pages-update-en-index-js": () => import("./../../../src/pages/update-en/index.js" /* webpackChunkName: "component---src-pages-update-en-index-js" */),
  "component---src-pages-update-index-js": () => import("./../../../src/pages/update/index.js" /* webpackChunkName: "component---src-pages-update-index-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-en-article-page-js": () => import("./../../../src/templates/en/article-page.js" /* webpackChunkName: "component---src-templates-en-article-page-js" */),
  "component---src-templates-en-index-page-js": () => import("./../../../src/templates/en/index-page.js" /* webpackChunkName: "component---src-templates-en-index-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-update-en-page-js": () => import("./../../../src/templates/update-en-page.js" /* webpackChunkName: "component---src-templates-update-en-page-js" */),
  "component---src-templates-update-page-js": () => import("./../../../src/templates/update-page.js" /* webpackChunkName: "component---src-templates-update-page-js" */)
}

